<template>
  <div class="slider-menu">
	<div class="left-slider">
	  <router-link class="logo" to="/home">
		<logo size="30" />
	  </router-link>
	  <div class="group-one">
		<!-- <router-link to="/dashboard">
		  <div class="link">
			<dashboard-two
			  :theme="iconStyle.theme"
			  :size="iconStyle.size"
			  :fill="iconStyle.fill"
			/>
			<span>仪表盘</span>
		  </div>
		</router-link> -->
		<router-link to="/permissions">
		  <div class="link">
			<protect
			  :theme="iconStyle.theme"
			  :size="iconStyle.size"
			  :fill="iconStyle.fill"
			/>
			<span>权限</span>
		  </div>
		</router-link>
		<router-link to="/users">
		  <div class="link">
			<user
			  :theme="iconStyle.theme"
			  :size="iconStyle.size"
			  :fill="iconStyle.fill"
			/>
			<span>用户</span>
		  </div>
		</router-link>
		<!-- <router-link to="/files">
		  <div class="link">
			<folder-open
			  :theme="iconStyle.theme"
			  :size="iconStyle.size"
			  :fill="iconStyle.fill"
			/>
			<span>文件</span>
		  </div>
		</router-link> -->
	  </div>

	  <div class="group-one">
		<router-link to="/recommends">
		  <div class="link">
			<ad-product
			  :theme="iconStyle.theme"
			  :size="iconStyle.size"
			  :fill="iconStyle.fill"
			/>
			<span>推荐位</span>
		  </div>
		</router-link>
		<router-link to="/content">
		  <div class="link">
			<newspaper-folding
			  :theme="iconStyle.theme"
			  :size="iconStyle.size"
			  :fill="iconStyle.fill"
			/>
			<span>内容</span>
		  </div>
		</router-link>
		<router-link to="/messages">
		  <div class="link">
			<comments
			  :theme="iconStyle.theme"
			  :size="iconStyle.size"
			  :fill="iconStyle.fill"
			/>
			<span>留言</span>
		  </div>
		</router-link>
	  </div>

	  <div class="group-one">
		<router-link to="/setting">
		  <div class="link">
			<setting
			  :theme="iconStyle.theme"
			  :size="iconStyle.size"
			  :fill="iconStyle.fill"
			/>
			<span>设置</span>
		  </div>
		</router-link>
	  </div>
	</div>
	<div class="child-menu" 
		v-if="$route.fullPath.indexOf('content') === -1"
		:class="showSubMenu && subMenu.children && subMenu.children.length > 0 ? 'show' : 'hide'"
	>
		<div class="child-menu-container">
			<h5>{{ subMenu.meta.title }}</h5>
			<n-submenu
				v-for="(item, index) in subMenu.children"
				:key="index"
				:menu="item"
			></n-submenu>
		</div>
	</div>
	<div v-else class="child-menu category"
		:class="showSubMenu ? 'show' : 'hide'"
	>
		<div class="child-menu-container">
			<h5>内容管理</h5>
			<div class="cat-slider">
                <div class="no-data" v-if="categories.length === 0">
					<img class="img" src="@/assets/no-data.svg" />
                    <span>暂无分类</span>
                </div>
                <div class="menu-container" v-else>
                    <div class="menu-item" 
                        v-for="(item, index) in categories" 
                        :key="index"
                    >
                        <div class="cat-link">
                            <span class="link-text" 
								:class="$route.params.id == item.id ? 'current' : ''"
								@click="goCategory(item)"
							>{{ item.title }}</span>
                            <div class="operation">
                                <edit 
                                    class="icon edit" 
                                    theme="outline" 
                                    size="14" 
                                    fill="#fff"
                                    @click="handlerEdit(item)"
                                />
								<delete 
									class="icon delete" 
									theme="outline" 
									size="14" 
									fill="#fff"
									@click="handlerDelete(item)"
								/>
                            </div>
                        </div>
                        <div class="children-container" v-if="item.children && item.children.length > 0">
                            <div 
                                class="child-item" 
                                v-for="(child, cIndex) in item.children" 
                                :key="cIndex"
                            >
                                <div class="cat-link">
                                    <span class="link-text" 
										:class="$route.params.id == child.id ? 'current' : ''"
										@click="goCategory(child)"
									>- {{ child.title }}</span>
                                    <div class="operation">
                                        <edit 
                                            class="icon edit" 
                                            theme="outline" 
                                            size="14" 
                                            fill="#fff"
                                            @click="handlerEdit(child)"
                                        />
                                        <delete 
                                            class="icon delete" 
                                            theme="outline" 
                                            size="14" 
                                            fill="#fff"
											@click="handlerDelete(child)"
                                        />
                                    </div>
                                </div>
								<div class="grandsun-container" v-if="child.children && child.children.length > 0">
									<div class="grandsun-item"
										v-for="(sun, sIndex) in child.children" :key="sIndex"
									>
										<div class="cat-link">
											<span class="link-text" 
												:class="$route.params.id == sun.id ? 'current' : ''"
												@click="goCategory(sun)"
											>- {{ sun.title }}</span>
											<div class="operation">
												<edit 
													class="icon edit" 
													theme="outline" 
													size="14" 
													fill="#fff"
													@click="handlerEdit(sun)"
												/>
												<delete 
													class="icon delete" 
													theme="outline" 
													size="14" 
													fill="#fff"
													@click="handlerDelete(sun)"
												/>
											</div>
										</div>
									</div>
								</div>
                            </div>
                        </div>
                    </div>
                </div>
                <n-button 
                    class="add-btn"
					type="primary"
                    @click="$router.push('/content/category/create')"
                >新建分类</n-button>
            </div>
		</div>
	</div>

	<n-modal 
		v-model:show="currentCat.show"
		height="auto"
	>
		<div class="operation-modal">
			<h4>提示</h4>
			<div class="modal-content">
				<span>确定永久删除这个分类吗<em>（一旦删除不可恢复）</em>？</span>
			</div>
			<div class="modal-tools">
				<n-button class="btn" @click="currentCat.show = false">取消</n-button>
				<n-button type="primary" :loading="btnLoading" @click="deleteCat(Number(currentCat.data.id))">确定</n-button>
			</div>
		</div>
	</n-modal>

  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import {
	DashboardTwo,
	Protect,
	User,
	FolderOpen,
	AdProduct,
	Page,
	NewspaperFolding,
	Comments,
	TreeList,
	Api,
	Setting,
	Edit, 
	Delete 
} from "@icon-park/vue-next";
import Logo from "@/components/layout/Logo.vue";
import menu from '@/mock/menu'

@Options({
	components: {
		// icon--park 图标
		DashboardTwo,
		Protect,
		User,
		FolderOpen,
		AdProduct,
		Page,
		NewspaperFolding,
		Comments,
		TreeList,
		Api,
		Setting,
		Edit, 
		Delete,
		Logo,
	},
	props: {
		showSubMenu: {
			type: Boolean,
			default: true,
		},
		iconStyle: {
			type: Object,
			default: {
				theme: "outline",
				size: 22,
				fill: "#fff",
			},
		},
	},
	data() {
		return {
			subMenu: {
				meta: { title: "" },
				children: [],
			},
			leftSliderIcon: {
				theme: "outline",
				size: 22,
				fill: "#fff",
			},
			iconObj: "",
			currentMenu: '',
			categories: [],
			currentCat: {
				data: {},
				show: false
			},
			btnLoading: false
		};
	},
	inject: ['reload'],
	watch: {
		$route(nVal){
			this.setSubMenu();
			this.reload();
		}
	},
	mounted(){
		this.setSubMenu();
	},
	methods: {
		setSubMenu(){
			if(this.$route.path.indexOf('dashboard') !== -1) this.subMenu = menu.dashboardSub;
			else if(this.$route.path.indexOf('permissions') !== -1) this.subMenu = menu.permissionsSub;
			else if(this.$route.path.indexOf('users') !== -1) this.subMenu = menu.userSub;
			else if(this.$route.path.indexOf('files') !== -1) this.subMenu = menu.filesSub;
			else if(this.$route.path.indexOf('recommends') !== -1) this.subMenu = menu.recommendSub;
			else if(this.$route.path.indexOf('setting') !== -1) this.subMenu = menu.settingSub;
			else this.subMenu = {
				meta: { title: '' },
				children: []
			};

			if(this.$route.fullPath.indexOf('content') !== -1) this.getCategories();
		},
		getCategories(){
            this.$api.xrCategories().then((res: any) => {
                this.categories = res.data;
            })
        },
        handlerEdit(item: any){
            this.$router.push(`/content/category/edit/${item.id}`);
        },
		handlerDelete(item: any){
			this.currentCat = {
				data: item,
				show: true
			}
		},
		deleteCat(id: number){
			this.btnLoading = true;
			this.$api.xrDestroyCategory(id).then((res: any) => {
				this.btnLoading = false;
				if(res.msg === 'success'){
					this.currentCat.show = false;
					this.getCategories();
				}
			})
		},
        goCategory(item: any){
            if(item.type === 'page'){
                this.$router.push(`/content/category/${item.id}/pages/edit`);
            } else if(item.type === 'equipment'){
                this.$router.push(`/content/category/${item.id}/equipments`);
            } else {
                this.$router.push(`/content/category/${item.id}/articles`);
            }
        }
	}
})
export default class SliderMenu extends Vue {}
</script>

<style lang="scss" scoped>
.slider-menu {
  @extend .flex-row;
  height: 100%;
  background-color: $white;
  box-shadow: $shadow;
  .left-slider {
	@extend .flex-column;
	width: 60px;
	height: 100%;
	background-image: linear-gradient(75deg, $linear-primary);
	.logo {
	  @extend .flex-column-center;
	  width: 60px;
	  height: 60px;
	}
	.group-one {
	  @extend .flex-column-center;
	  padding: 20px 0;
	  border-top: 1px solid rgba(0, 0, 0, 0.2);
	  .link {
		@extend .flex-column-center;
		width: 60px;
		height: 60px;
		color: $white;
	  }
	  a {
		&:hover,
		&.router-link-active {
		  background-color: rgba(0, 0, 0, 0.2);
		}
	  }
	}
  }
  .child-menu {
	overflow: hidden;
	&-container {
	  width: 160px;
	  h5 {
		@extend .flex-column-middle;
		height: 61px;
		margin: 0;
		padding: 0 10px;
		border-bottom: 1px solid $border-color;
		font-size: 14px;
	  }
	}
	&.show {
	  width: 160px;
	}
	&.hide {
	  width: 0;
	}

	&.category{
		height: 100%;
		overflow-y: auto;
		&::-webkit-scrollbar {
            width: 4px;
        }
		&::-webkit-scrollbar-thumb {
			position: absolute;
			border-radius: 10px;
			box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
			background: #535353;
		}
		&::-webkit-scrollbar-track {
			display: none;
			// box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
			border-radius: 10px;
			// background: #EDEDED;
		}
		.child-menu-container{
			width: 260px;
		}
		&.show{
			width: 260px;
		}
		.cat-slider{
			@extend .flex-column;
			padding: 10px 0;
			.no-data{
				@extend .flex-column-center;
				.img{
					width: 190px;
				}
			}
			.menu-container{
				@extend .flex-column;
				.cat-link{
					@extend .flex-row-between;
					cursor: pointer;
					padding: 10px;
					font-size: 14px;
					&:hover{
						color: $primary;
					}
					.link-text{
						width: 75%;
						line-height: 22px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						&.current{
							color: $primary;
						}
					}
					.operation{
						@extend .flex-row;
						.icon{
							@extend .flex-row-center;
							width: 22px;
							height: 22px;
							&:first-child{
								border-radius: $radius-big 0 0 $radius-big;
							}
							&:last-child{
								border-radius: 0 $radius-big $radius-big 0;
							}
							&.edit{
								background-color: $primary;
							}
							&.delete{
								background-color: $danger;
							}
						}
					}
				}
				.children-container{
					margin-left: 10px;
					.cat-link{
						padding: 8px 10px;
						font-size: 12px;
					}
					.grandsun-container{
						margin-left: 10px;
						.cat-link{
							font-size: 11px;
						}
					}
				}
			}
			.add-btn{
				margin: 20px;
			}
		}
	}
  }

  // 模态框
	.operation-modal{
		@extend .flex-column;
		h4{
			margin: 15px;
			font-size: 14px;
		}
		.modal-content{
			padding: 0 15px;
			font-size: 14px;
			em{
				color: $danger;
			}
		}
		.modal-tools{
			@extend .flex-row;
			padding: 15px;
			justify-content: flex-end;
			.btn{
				margin-right: 15px;
			}
		}
	}
}
</style>