
import { Vue, Options } from 'vue-class-component';

@Options({
    props: {
        size: {
            type: String,
            default: '22'
        }
    }
})

export default class Logo extends Vue {}
