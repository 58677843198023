<template>
    <div class="n-header">
        <div class="left-container">
            <div class="icon-btn" @click="$emit('update:showSubMenu', !showSubMenu)">
                <menu-fold-one v-if="showSubMenu" class="icon" theme="outline" size="20" fill="#333" :strokeWidth="3"/>
                <menu-unfold-one v-else class="icon" theme="outline" size="20" fill="#333" :strokeWidth="3"/>
            </div>
            <div class="icon-btn" @click="handlerRefresh">
                <refresh class="icon" :class="reloading ? 'reloading' : ''" theme="outline" size="20" fill="#333" :strokeWidth="3"/>
            </div>
        </div>
        <div class="right-container">

            <!--  -->
            <div class="i18n-box">
                <n-dropdown width="85px" position="top right">
                    <template v-slot:link>
                        <div class="i18n-link">
                            <translate class="" theme="outline" size="20" fill="#333"/>
                        </div>
                    </template>
                    <template v-slot:menu>
                        <div class="i18n-item">English</div>
                        <div class="i18n-item">简体中文</div>
                    </template>
                </n-dropdown>
            </div>

            <div class="user-avatar">
                <n-dropdown width="110px" position="right top">
                    <template v-slot:link>
                        <n-avatar name="Admin" :size="40"></n-avatar>
                    </template>
                    <template v-slot:menu>
                        <router-link class="menu-item" to="/profile">
                            <user class="icon" theme="outline" size="14" fill="#999"/>
                            <span class="text">用户资料</span>
                        </router-link>
                        <div class="menu-item" @click="logout">
                            <logout class="icon" theme="outline" size="14" fill="#999"/>
                            <span class="text">退出登录</span>
                        </div>
                    </template>
                </n-dropdown>
            </div>
            
        </div>
    </div> 

</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { 
    MenuFoldOne, MenuUnfoldOne, 
    Refresh, User, Logout,
    Translate, MessageOne
} from '@icon-park/vue-next';

@Options({
    components: {
        MenuFoldOne, MenuUnfoldOne, 
        Refresh, User, Logout,
        Translate, MessageOne
    },
    props: {
        showSubMenu: {
            type: Boolean,
            default: true
        }
    },
    inject: ['reload'],
    data(){
        return{
            keyword: '',
            reloading: false
        }
    },
    emits: ['update:showSubMenu'],
    methods: {
        handlerRefresh(){
            this.reloading = true;
            setTimeout(() => {
                this.reloading = false;
            }, 1000);
            this.reload()
        },
        handlerSearch(e: any){
            console.log(123123123)
        },
        logout(){
            sessionStorage.removeItem('accessToken')
            localStorage.removeItem('accessToken')
            this.$store.commit('changeAccessToken', '')
            this.$router.push({ path: '/' })
        }
    }
})

export default class NHeader extends Vue {}
</script>

<style lang="scss" scoped>
.n-header{
    @extend .flex-row-between;
    width: 100%;
    height: 60px;
    border-left: 1px solid $border-color;
    background-color: $white;
    box-shadow: $shadow;
    .left-container{
        @extend .flex-row;
        .icon-btn{
            @extend .flex-column-middle;
            cursor: pointer;
            padding: 0 10px;
            &:hover .icon{
                opacity: 1;
            }
            .icon{
                width: 20px;
                height: 20px;
                opacity: .5;
                &.reloading{
                    animation: rotate 1s linear infinite;
                }
            }
        }
        .search{
            @extend .flex-column-middle;
            width: 220px;
            margin-left: 20px;
        }
    }
    .right-container{
        @extend .flex-row;
        margin-right: 10px;
        .user-avatar{
            margin: 10px;
            .menu-item{
                @extend .flex-row;
                cursor: pointer;
                padding: 10px;
                color: $text-color;
                .icon{
                    @extend .flex-column-middle;
                    margin-right: 5px;
                }
                &:hover, &.router-link-active{
                    background-color: $primary-light;
                    color: $primary;
                }
                &:first-child{
                    border-radius: $radius $radius 0 0;
                }
                &:last-child{
                    border-radius: 0 0 $radius $radius;
                }
            }
        }
        .i18n-box{
            margin-right: 10px;
            .i18n-link{
                @extend .flex-column-middle;
                cursor: pointer;
                width: 40px;
                height: 60px;
                padding-right: 10px;
                text-align: right;
                box-sizing: border-box;
            }
            .i18n-item{
                cursor: pointer;
                padding: 10px;
                &:hover{
                    color: $primary;
                    background-color: $primary-light;
                }
                &:first-child{
                    border-radius: $radius $radius 0 0;
                }
                &:last-child{
                    border-radius: 0 0 $radius $radius;
                }
            }
        }
        .message-box{
            margin-right: 10px;
            .message-link{
                @extend .flex-column-middle;
                text-align: right;
                width: 40px;
                height: 60px;
                padding-right: 10px;
                box-sizing: border-box;
                cursor: pointer;
            }
        }
    }
}
</style>