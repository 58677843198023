<template>
    <div class="footer">
        <div class="copyright">Copyright © 2020 7NIAO.NET 七鸟互联</div>
        <div class="right">
            <a href="https://7niao.net" target="_blank">7NIAO.NET</a>
            <a href="https://nsys.7niao.net" target="_blank">NSYS</a>
            <a href="https://nui.7niao.net" target="_blank">NUI</a>
            <a href="https://nadmin.7niao.net" target="_blank">NADMIN</a>
            <a href="https://nblog.7niao.net" target="_blank">NBLOG</a>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({

})

export default class NFooter extends Vue {}
</script>

<style lang="scss" scoped>
.footer{
    @extend .flex-row-between;
    margin: 30px 20px;
    font-size: 12px;
    color: $info;
    .right{
        @extend .flex-row;
        a{
            margin-left: 15px;
            color: $info;
            &:hover{
                color: $primary;
            }
        }
    }
}
</style>