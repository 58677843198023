
import { Options, Vue } from 'vue-class-component';
import { 
    MenuFoldOne, MenuUnfoldOne, 
    Refresh, User, Logout,
    Translate, MessageOne
} from '@icon-park/vue-next';

@Options({
    components: {
        MenuFoldOne, MenuUnfoldOne, 
        Refresh, User, Logout,
        Translate, MessageOne
    },
    props: {
        showSubMenu: {
            type: Boolean,
            default: true
        }
    },
    inject: ['reload'],
    data(){
        return{
            keyword: '',
            reloading: false
        }
    },
    emits: ['update:showSubMenu'],
    methods: {
        handlerRefresh(){
            this.reloading = true;
            setTimeout(() => {
                this.reloading = false;
            }, 1000);
            this.reload()
        },
        handlerSearch(e: any){
            console.log(123123123)
        },
        logout(){
            sessionStorage.removeItem('accessToken')
            localStorage.removeItem('accessToken')
            this.$store.commit('changeAccessToken', '')
            this.$router.push({ path: '/' })
        }
    }
})

export default class NHeader extends Vue {}
