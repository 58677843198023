
import { Options, Vue } from "vue-class-component";
import {
	DashboardTwo,
	Protect,
	User,
	FolderOpen,
	AdProduct,
	Page,
	NewspaperFolding,
	Comments,
	TreeList,
	Api,
	Setting,
	Edit, 
	Delete 
} from "@icon-park/vue-next";
import Logo from "@/components/layout/Logo.vue";
import menu from '@/mock/menu'

@Options({
	components: {
		// icon--park 图标
		DashboardTwo,
		Protect,
		User,
		FolderOpen,
		AdProduct,
		Page,
		NewspaperFolding,
		Comments,
		TreeList,
		Api,
		Setting,
		Edit, 
		Delete,
		Logo,
	},
	props: {
		showSubMenu: {
			type: Boolean,
			default: true,
		},
		iconStyle: {
			type: Object,
			default: {
				theme: "outline",
				size: 22,
				fill: "#fff",
			},
		},
	},
	data() {
		return {
			subMenu: {
				meta: { title: "" },
				children: [],
			},
			leftSliderIcon: {
				theme: "outline",
				size: 22,
				fill: "#fff",
			},
			iconObj: "",
			currentMenu: '',
			categories: [],
			currentCat: {
				data: {},
				show: false
			},
			btnLoading: false
		};
	},
	inject: ['reload'],
	watch: {
		$route(nVal){
			this.setSubMenu();
			this.reload();
		}
	},
	mounted(){
		this.setSubMenu();
	},
	methods: {
		setSubMenu(){
			if(this.$route.path.indexOf('dashboard') !== -1) this.subMenu = menu.dashboardSub;
			else if(this.$route.path.indexOf('permissions') !== -1) this.subMenu = menu.permissionsSub;
			else if(this.$route.path.indexOf('users') !== -1) this.subMenu = menu.userSub;
			else if(this.$route.path.indexOf('files') !== -1) this.subMenu = menu.filesSub;
			else if(this.$route.path.indexOf('recommends') !== -1) this.subMenu = menu.recommendSub;
			else if(this.$route.path.indexOf('setting') !== -1) this.subMenu = menu.settingSub;
			else this.subMenu = {
				meta: { title: '' },
				children: []
			};

			if(this.$route.fullPath.indexOf('content') !== -1) this.getCategories();
		},
		getCategories(){
            this.$api.xrCategories().then((res: any) => {
                this.categories = res.data;
            })
        },
        handlerEdit(item: any){
            this.$router.push(`/content/category/edit/${item.id}`);
        },
		handlerDelete(item: any){
			this.currentCat = {
				data: item,
				show: true
			}
		},
		deleteCat(id: number){
			this.btnLoading = true;
			this.$api.xrDestroyCategory(id).then((res: any) => {
				this.btnLoading = false;
				if(res.msg === 'success'){
					this.currentCat.show = false;
					this.getCategories();
				}
			})
		},
        goCategory(item: any){
            if(item.type === 'page'){
                this.$router.push(`/content/category/${item.id}/pages/edit`);
            } else if(item.type === 'equipment'){
                this.$router.push(`/content/category/${item.id}/equipments`);
            } else {
                this.$router.push(`/content/category/${item.id}/articles`);
            }
        }
	}
})
export default class SliderMenu extends Vue {}
