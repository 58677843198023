<template>
    <div class="layout">
        <slider-menu :showSubMenu="showSubMenu" v-if="menuAlive"></slider-menu>
        <n-scroll-view class="layout-container" size="small" height="auto">
            <n-header class="header" v-model:showSubMenu="showSubMenu"></n-header>
            <div class="body">
                <router-view v-if="routerAlive" />
            </div>
            <n-footer />
        </n-scroll-view>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import SliderMenu from '@/components/layout/SliderMenu.vue';
import NHeader from '@/components/layout/Header.vue';
import NFooter from '@/components/layout/Footer.vue';

@Options({
    components: {
        SliderMenu, NHeader, NFooter
    },
    provide(){
        return {
            reload: this.reload,
            menuReload: this.menuReload
        }
    },
    data(){
        return{
            showSubMenu: true,
            routerAlive: true,
            menuAlive: true
        }
    },
    mounted(){
        let accessToken = this.$store.state.accessToken;
        accessToken = accessToken === '' ? sessionStorage.getItem('accessToken') : accessToken
        if(accessToken === null || accessToken === '') this.$router.push({ path: '/' });
        else {
            this.$api.getUserInfo().then((res: any) => {
                this.$store.commit('changeUserInfo', res.data);
            })
        }

        console.info('%c七鸟互联 - NSYS', 'color: #503cff; font-size: 24px; font-weight: bold; padding: 10px 0;')
        console.info('%c请不要在此处粘贴执行任何内容', 'color: #ff2121; font-size: 14px; font-weight: bold;')
    },
    methods: {
        reload(){
            this.routerAlive = false;
            this.$nextTick(() => {
                this.routerAlive = true;
            })
        },
        menuReload(){
            this.menuAlive = false;
            this.$nextTick(() => {
                this.menuAlive = true;
            })
        }
    }
})

export default class Dashboard extends Vue {}
</script>

<style lang="scss" scoped>
.layout{
    @extend .flex-row;
    width: 100%;
    height: 100%;
    &-container{
        width: 100%;
        .header{
            z-index: 9;
            position: sticky;
            top: 0;
        }
        .body{
            width: 100%;
            height: 100%;
        }
    }
    
}
</style>