

const dashboardSub = {
    path: '/dashboard',
    meta: {
        title: '仪表盘'
    },
    children: [
        {
            path: '/dashboard/analysis',
            meta: {
                title: '分析页'
            },
        }, {
            path: '/dashboard/monitor',
            meta: {
                title: '监控页'
            },
        }, {
            path: '/dashboard/workplace',
            meta: {
                title: '工作台'
            },
        }
    ]
}

const permissionsSub = {
    path: '/permissions-roles',
    meta: {
        title: '权限 & 角色'
    },
    children: [
        {
            path: '/permissions/permissions',
            name: 'Permissions',
            meta: {
                title: '权限管理'
            }
        }, {
            path: '/permissions/roles',
            name: 'Roles',
            meta: {
                title: '角色管理'
            }
        }
    ]
}

const userSub = {
    path: '/users-admins',
    meta: {
        title: '用户 & 管理员'
    },
    children: [
        {
            path: '/users/users',
            name: 'Users',
            meta: {
                title: '用户管理'
            }
        },{
            path: '/users/admins',
            name: 'admins',
            meta: {
                title: '管理员管理'
            }
        }
    ]
}

const filesSub = {
    path: '/files',
    meta: {
        title: '文件管理'
    },
    children: [
        {
            path: '/files/all',
            name: 'All Files',
            meta: {
                title: '全部'
            }
        }, {
            path: '/files/documents',
            name: 'Documents',
            meta: {
                title: '文档'
            }
        }, {
            path: '/files/pictures',
            name: 'Pictures',
            meta: {
                title: '图片'
            }
        }, {
            path: '/files/videos',
            name: 'Videos',
            meta: {
                title: '视频'
            }
        }, {
            path: '/files/audios',
            name: 'Audios',
            meta: {
                title: '音频'
            }
        }, {
            path: '/files/others',
            name: 'Others',
            meta: {
                title: '其他'
            }
        }
    ]
}

const recommendSub = {
    path: '/recommends',
    meta: {
        title: '推荐位'
    },
    children: [
        {
            path: '/recommends/home-carousel',
            name: 'Home Carousel',
            meta: {
                title: '首页轮播图'
            }
        },{
            path: '/recommends/home-about',
            name: 'Home About',
            meta: {
                title: '首页关于'
            }
        },{
            path: '/recommends/home-service',
            name: 'Home Service',
            meta: {
                title: '首页服务'
            }
        }
    ]
}

const settingSub = {
    path: '/setting',
    meta: {
        title: '设置'
    },
    children: [
        {
            path: '/setting/website',
            name: 'Website Setting',
            meta: {
                title: '网站设置'
            }
        },{
            path: '/setting/contact',
            name: 'Contact Setting',
            meta: {
                title: '联系设置'
            }
        }
    ]
}

export default {
    // 仪表盘
    dashboardSub,
    // 权限管理
    permissionsSub,
    // 用户管理
    userSub,
    // 文件管理
    filesSub,
    // 推荐位管理
    recommendSub,
    // 设置管理
    settingSub
}